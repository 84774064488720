/** TABLE CUSTOM STYLES**/

.card-with-data-table .card-title {
    padding: 15px 0px 10px 25px;
}

.speys-data-table .table td {
    padding: 0.25rem 0.5rem;
}

.speys-data-table-container{
    box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.2),0px 1px 1px 0px rgba(0, 0, 0, 0.14),0px 2px 1px -1px rgba(0, 0, 0, 0.12);
}

.speys-data-table .pagination{
    float: right;
    margin-right: 15px;
}

.table-popup-menu li{
    height: 12px;
}
.table-popup-menu li i{
    padding-right: 10px;
}
.table-popup-menu li, .table-popup-menu li a{
    color: #333333;
    font-size: 0.8rem;
}
.react-bs-table-pagination{
    padding-bottom: 10px;
}
.modal-dialog .react-bs-table-pagination .dropdown-menu {
    top: 1px !important;
}