.rct-sidebar .rct-sidebar-content.sidebar-overlay-dark::before {
    background: linear-gradient(0deg, #221D34 0%, #221D34 100%);
}

.speys-system .rct-page-content {
    padding: 0;
}

.pointer {
    cursor: pointer;
}

.hidden {
    display: none;
}

.app-header .rct-header {
    background-color: #FFF;
    color: #ffe806;
}

.app-main {
    height: 100%;
}

.font-bold {
    font-weight: bold;
}

.text-speys-yellow {
    color: #ffe806 !important;
}

.display-block {
    display: block;
}

.position-fixed {
    position: fixed;
    width: 100%;
    z-index: 999;
    margin-left: 0;
}

.bg-light-part {
    background-color: rgba(0, 0, 0, .02)
}

.rct-sidebar-wrap .navigation li.list-item-active{
    font-weight: bold;

    /*background-color: rgba(0, 0, 0, 0.2);*/
}
.rct-sidebar-wrap .navigation li.list-item-active .menu{
    color:#FFE806;
}

/** LINKS **/
a.link-text-black{
    color: #464D69;
}

/** BUTTONS **/
.btn-speys{
    background: #ffe806 ;
    color: #111  !important;
}
.btn-speys:hover{
    color: #4d4d4d !important;
    background: #e6cf00;
}

.content-container-top-nav .btn-sm {
    font-size: 14px;
    padding: 0.15rem 0.5rem;
}
#render-props-menu{
    z-index: 900 !important;
}
#render-props-menu ul li > a{
    width: 100%;
}
.btn *, a[role="button"], button[type="button"]{
    text-transform: none;
}

.btn-outline-secondary.no-border{
     border-color: #FFF;
 }
.btn-outline-secondary.no-border:hover, .btn-outline-secondary.no-border:active{
    background-color: #FFF;
    color:#464D69;
}
/** MATERIAL UI SPEYS**/
.table-action-button {
    width: 32px !important;
    height: 32px !important;
    font-size: 1.1rem !important;
}

.speys-tabs {
    background-color: #fff;
    color: #464D69;
}

.speys-tabs button {
    font-size: 1rem;
}

.speys-system-tabs {
    z-index: 1 !important;
}

/** SIDEBAR **/
.company-info p{
    font-size: 1.045rem;
    margin-top: 0.6rem;
    margin-bottom: 0;
}
.rct-sidebar .rct-sidebar-nav .rct-mainMenu > li:not(.list-item) a {
    padding: 0.30rem 1.5rem;
    font-size: 0.835rem;
}

.rct-sidebar .rct-sidebar-nav .rct-mainMenu li.list-item span.menu,
.rct-sidebar .rct-sidebar-nav .rct-mainMenu li.list-item .menu-icon {
    font-size: 0.835rem;
}

.rct-sidebar .rct-sidebar-nav .rct-mainMenu .sub-menu ul li a {
    padding: 0.30rem 1.5rem 0.30rem 3.4rem;
    font-size: 0.835rem;
}

.rct-sidebar-nav .navigation li:hover {
    background-color: rgba(255, 232, 6, 0.5);
}

.rct-sidebar .rct-sidebar-nav .rct-mainMenu li.list-item {
    padding: 0.45rem 1.5rem;
    font-size: 0.9rem;
}

/*FORM*/
.form-control{
    border: 1px solid #ccc;
}
.form-group .checkbox label{
    margin-left: 10px;
}

.form-inline.form-group-radio label{
    margin-right: 10px;
}
.form-inline.form-group-radio input{
    margin-right: 5px;
}
.form-group .disabled {
    background-color: #F0F0F0;
}
.input-group.full-width{
    width: 100%;
    display: block;
}
.expandable-wrapper-with-form .expandable-header .text-normal {
    font-size: 0.82rem;
}

.expandable-wrapper-with-form.collapse-in div:nth-child(2) {
    overflow: visible;
}

.Select-menu-outer {
    z-index: 999 !important;
}

.Select.is-open {
    position: relative;
    z-index: 1000;
}

.expandable-wrapper-with-form.collapse-in .expandable-content {
    animation: 0.5s fadeIn;
}

.form-group .help-block ul{
    list-style: none;
}
.form-group .help-block ul li{
    margin-left: 10px;
    margin-top: 5px;
    color: #f44336;
    font-size: 0.75rem;
}
.form-group label .required{
    margin-left: 3px;
    color: #3A7BF2;
}
.form-group.error label .required{
    color: #f44336;
}
.form-group.error .form-control,.form-group.error .Select-control{
    border: 1px solid #f98e86;

}
.form-group.error .form-control:focus{
   /* box-shadow: inset 0 1px 1px rgba(244, 67, 54, 0.75);*/
}

.debug {
    border: 2px solid aqua;
}

/* LOGIN VIEW*/
.login-view .rct-session-wrapper .has-wrapper .has-input {
    border: none !important;
    background-image: linear-gradient(#009efb, #009efb), linear-gradient(#d9d9d9, #d9d9d9);
    background-color: transparent;
    background-position: center bottom, center calc(100% - 1px);
    background-size: 0 2px, 100% 1px;
    background-repeat: no-repeat;
    box-shadow: none;

    background-image: -webkit-gradient(linear, left top, left bottom, from(#009efb), to(#009efb)), -webkit-gradient(linear, left top, left bottom, from(#d9d9d9), to(#d9d9d9));
    background-image: -webkit-linear-gradient(#009efb, #009efb), -webkit-linear-gradient(#d9d9d9, #d9d9d9);
    background-image: -o-linear-gradient(#009efb, #009efb), -o-linear-gradient(#d9d9d9, #d9d9d9);
}

.login-view .rct-session-wrapper .has-wrapper .has-input:focus {
    background-image: -webkit-gradient(linear, left top, left bottom, from(#009efb), to(#009efb)), -webkit-gradient(linear, left top, left bottom, from(#d9d9d9), to(#d9d9d9));
    background-image: -webkit-linear-gradient(#009efb, #009efb), -webkit-linear-gradient(#d9d9d9, #d9d9d9);
    background-image: -o-linear-gradient(#009efb, #009efb), -o-linear-gradient(#d9d9d9, #d9d9d9);
    background-image: linear-gradient(#009efb, #009efb), linear-gradient(#d9d9d9, #d9d9d9);
    border: 0 none;
    border-radius: 0;
    -webkit-box-shadow: none;
    box-shadow: none;
    float: none;
}

.login-view .rct-session-wrapper .has-wrapper .has-input {
    background-color: transparent;
    background-position: center bottom, center calc(100% - 1px);
    background-repeat: no-repeat;
    background-size: 0 2px, 100% 1px;
    padding: 0;
    -webkit-transition: background 0s ease-out 0s;
    -o-transition: background 0s ease-out 0s;
    transition: background 0s ease-out 0s;
}

.login-view .rct-session-wrapper .has-wrapper .has-input:focus {
    background-size: 100% 2px, 100% 1px;
    outline: 0 none;
    -webkit-transition-duration: 0.3s;
    -o-transition-duration: 0.3s;
    transition-duration: 0.3s;
}

.login-language-switcher .quciklink-dropdown .dropdown-menu,.login-language-switcher .notification-dropdown .dropdown-menu,.login-language-switcher .language-dropdown .dropdown-menu, .cart-dropdown .dropdown-menu{
    width: 210px;
}
.login-language-switcher .quciklink-dropdown .dropdown-menu .dropdown-list li,.login-language-switcher .notification-dropdown .dropdown-menu .dropdown-list li,.login-language-switcher .language-dropdown .dropdown-menu .dropdown-list li, .login-language-switcher .cart-dropdown .dropdown-menu .dropdown-list li{
    width: 100%;
    margin: 0.1rem 0;
}
.login-language-switcher .language-dropdown .dropdown-menu,.login-language-switcher .cart-dropdown .dropdown-menu{
    left: -8rem !important;
}

/* HEADER */
.language-switcher .dropdown-toggle::after{
    color: #464D69;
}
/*DASHBOARD*/
.ct-dashboard .current-widget{
    padding: 0.875rem;
    margin-bottom: 0px;
}
.ct-dashboard .current-widget h3 {
    font-size: 1.0rem;
}
.ct-dashboard .current-widget h2 {
    font-size: 1.3rem;
    margin-left: 10px;
}
.ct-dashboard .nav .nav-link {
    color: #a6a6a6;
}
.ct-dashboard .current-widget .align-items-end span{
    font-size: 1.345rem;
    font-weight: bold;
}
.rct-sidebar .rct-sidebar-wrap .top-sidebar .sidebar-user-block{
    padding: 0 1.25rem 1.25rem;
}
.welcome-text {
    position: absolute;
    bottom: 0;
    left: 20px;
}

.ct-dashboard .nav .nav-link.active,
.ct-dashboard .nav .nav-link:hover {
    color: #ffe806;
}

.ct-dashboard .table, .ct-table .table {
    margin-bottom: 0;
}

.ct-dashboard .table td {
    padding: 0.25rem 0.5rem;
}

.ct-dashboard .expandable-header {
    min-height: 40px !important;
    padding: 15px 0px 10px 25px;
}

.ct-dashboard .expandable-header > div {
    margin: 0;
}

.ct-dashboard .expandable-content {
    padding: 0.5rem 0 0;
}

.ct-dashboard .bg-dark {
    background-color: #2e2a3c !important;
}

.ct-dashboard .expandable-content {
    display: block;
}
.dashboard-sidebar{
    z-index: 998 !important;
}
.dashboard-sidebar .MuiPaper-root-017 {
    background-color: #fafbff;
}
.dashboard-action-buttons button{
    font-size: 1rem !important;
}
/*SHIPMENT VIEW*/
.status-select .Select{
    float: left;
    width: 275px;
    margin-left: 10px;
}
.shipment-detail-rows .form-group {
    margin-bottom: 0.8rem;
}

.ui.styled.accordion .title, .ui.styled.accordion .accordion .title {
    padding: 0 1rem !important;
    line-height: 35px;
}

.shipment-details .expandable-header {
    color: rgba(0, 0, 0, 0.54);
    background-color: #E3E6E8;
    min-height: 40px !important;
    padding: 10px 0px 7px 25px;
    z-index: 1;
}

.shipment-details .expandable-header > div {
    margin: 0;
}

.shipment-details .expandable-content {
    display: block;
}

.shipment-detail-rows .package-row .title:nth-child(even),
.shipment-detail-rows .package-row .content:nth-child(even) {
    /*border-top: 1px solid #d9d9d9;*/
    background: #f2f2f2 !important;
}

.shipment-detail-rows .package-row .title:nth-child(odd),
.shipment-detail-rows .package-row .content:nth-child(odd) {
    /*border-top: 1px solid #d9d9d9;*/
    background: #f2f2f2 !important;
}

.shipment-detail-rows .accordion .title .text-normal {
    font-weight: normal;
    font-size: 13px;
}

.shipment-detail-tabs .ui.segment {
    background: none;
    border: 0px;
    -webkit-box-shadow: none;
}
.shipment-confirm-pop-up td{
    padding: 0.45rem 0.75rem 0.25rem;
}
/*Wizard*/
.wizard-content .wizard > .steps.green > ul > li.current .step {
    border-color: #06d79c;
    background-color: #fff;
    color: #06d79c
}

/* MODAL */
.modal-dialog.modal-xs{
    max-width: 200px !important;
}