// Pagination
.react-bs-table-pagination .react-bootstrap-table-page-btns-ul .pagination {
  float: right;
  margin-right: 15px;
}

.react-bs-table-pagination .react-bs-table-sizePerPage-dropdown button{
  margin-left: 15px;
}
@mixin pagination-size($padding-y, $padding-x, $font-size, $line-height, $border-radius) {
  .page-link {
    padding: $padding-y $padding-x;
    font-size: $font-size;
    line-height: $line-height;
  }

  .page-item {
    &:first-child {
      .page-link {
        @include border-left-radius($border-radius);
      }
    }
    &:last-child {
      .page-link {
        @include border-right-radius($border-radius);
      }
    }
  }
}
