/* Switch
   ========================================================================== */
.switch,
.switch * {
    -webkit-user-select: none;
    -moz-user-select: none;
    -khtml-user-select: none;
    -ms-user-select: none; }

.switch label {
    cursor: pointer; }

.switch label input[type=checkbox] {
    opacity: 0;
    width: 0;
    height: 0; }

.switch label input[type=checkbox]:checked + .lever {
    background-color: #84c7c1; }

.switch label input[type=checkbox]:checked + .lever:after {
    background-color: #26a69a;
    left: 24px; }

.switch label .lever {
    content: "";
    display: inline-block;
    position: relative;
    width: 40px;
    height: 15px;
    background-color: #818181;
    border-radius: 15px;
    margin-right: 10px;
    -webkit-transition: background 0.3s ease;
    -o-transition: background 0.3s ease;
    transition: background 0.3s ease;
    vertical-align: middle;
    margin: 0 16px; }

.switch label .lever:after {
    content: "";
    position: absolute;
    display: inline-block;
    width: 21px;
    height: 21px;
    background-color: #F1F1F1;
    border-radius: 21px;
    -webkit-box-shadow: 0 1px 3px 1px rgba(0, 0, 0, 0.4);
    box-shadow: 0 1px 3px 1px rgba(0, 0, 0, 0.4);
    left: -5px;
    top: -3px;
    -webkit-transition: left 0.3s ease, background .3s ease, -webkit-box-shadow 0.1s ease;
    transition: left 0.3s ease, background .3s ease, -webkit-box-shadow 0.1s ease;
    -o-transition: left 0.3s ease, background .3s ease, box-shadow 0.1s ease;
    transition: left 0.3s ease, background .3s ease, box-shadow 0.1s ease;
    transition: left 0.3s ease, background .3s ease, box-shadow 0.1s ease, -webkit-box-shadow 0.1s ease; }

input[type=checkbox]:checked:not(:disabled) ~ .lever:active::after,
input[type=checkbox]:checked:not(:disabled).tabbed:focus ~ .lever::after {
    -webkit-box-shadow: 0 1px 3px 1px rgba(0, 0, 0, 0.4), 0 0 0 15px rgba(38, 166, 154, 0.1);
    box-shadow: 0 1px 3px 1px rgba(0, 0, 0, 0.4), 0 0 0 15px rgba(38, 166, 154, 0.1); }

input[type=checkbox]:not(:disabled) ~ .lever:active:after,
input[type=checkbox]:not(:disabled).tabbed:focus ~ .lever::after {
    -webkit-box-shadow: 0 1px 3px 1px rgba(0, 0, 0, 0.4), 0 0 0 15px rgba(0, 0, 0, 0.08);
    box-shadow: 0 1px 3px 1px rgba(0, 0, 0, 0.4), 0 0 0 15px rgba(0, 0, 0, 0.08); }

.switch input[type=checkbox][disabled] + .lever {
    cursor: default; }

.switch label input[type=checkbox][disabled] + .lever:after,
.switch label input[type=checkbox][disabled]:checked + .lever:after {
    background-color: #BDBDBD; }

.scale-up {
    -webkit-transition: all 0.3s ease;
    -o-transition: all 0.3s ease;
    transition: all 0.3s ease;
    -webkit-transform: scale(0);
    -ms-transform: scale(0);
    transform: scale(0);
    display: inline-block;
    -webkit-transform-origin: right 0px;
    -ms-transform-origin: right 0px;
    transform-origin: right 0px; }

.scale-up-left {
    -webkit-transition: all 0.3s ease;
    -o-transition: all 0.3s ease;
    transition: all 0.3s ease;
    -webkit-transform: scale(0);
    -ms-transform: scale(0);
    transform: scale(0);
    display: inline-block;
    -webkit-transform-origin: left 0px;
    -ms-transform-origin: left 0px;
    transform-origin: left 0px; }

.show > .scale-up {
    -webkit-transform: scale(1);
    -ms-transform: scale(1);
    transform: scale(1);
    -webkit-transform-origin: right 0px;
    -ms-transform-origin: right 0px;
    transform-origin: right 0px; }

.show > .scale-up-left {
    -webkit-transform: scale(1);
    -ms-transform: scale(1);
    transform: scale(1);
    -webkit-transform-origin: left 0px;
    -ms-transform-origin: left 0px;
    transform-origin: left 0px; }

/* FILE UPLOAD*/

div.filepicker{
    text-align: center;
    padding: 5px;
    background-color: #E1E1E1;
    border-radius: 5px;
    min-height: 130px;
    border: 2px dashed #C7C7C7;
}